var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Версии приложения","create":"","create-title":"Создать версию","url-create":"/version/create","url-update":"/version/update/:id","store-module":"version","headers":[
        { text: 'Платформа', value: 'platform_name', width: 1 },
        { text: 'Версия', value: 'version', width: 1 },
        { text: 'Текущая версия', value: 'current', width: 1 },
        { text: 'Файл', value: 'file'},
        { text: 'Ссылка', value: 'url'},
        { text: '', value: 'download', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems}})}
var staticRenderFns = []

export { render, staticRenderFns }
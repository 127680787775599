<template>
    <grid-view 
        title="Версии приложения"
        create
        create-title="Создать версию"
        url-create="/version/create"
        url-update="/version/update/:id"
        store-module="version"
        :headers="[
            { text: 'Платформа', value: 'platform_name', width: 1 },
            { text: 'Версия', value: 'version', width: 1 },
            { text: 'Текущая версия', value: 'current', width: 1 },
            { text: 'Файл', value: 'file'},
            { text: 'Ссылка', value: 'url'},
            { text: '', value: 'download', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
    >
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                _.set(item, 'current', item.current === true ? 'Да' : 'Нет');
                _.set(item, 'download', item.url);

                return item;
            })
        } 
    }
}
</script>